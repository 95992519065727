
    import { template as compiler } from "discourse/lib/raw-handlebars";
    import { addRawTemplate } from "discourse/lib/raw-templates";

    let template = compiler({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <tr class='topic-list-item-separator'>\n    <td class=\"topic-list-data\" colspan=\"6\">\n      <span>\n        "
    + alias3((lookupProperty(helpers,"d-icon")||(depth0 && lookupProperty(depth0,"d-icon"))||alias2).call(alias1,"bp-caret",{"name":"d-icon","hash":{},"hashTypes":{},"hashContexts":{},"types":["StringLiteral"],"contexts":[depth0],"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":29}}}))
    + "\n        "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"topics.new_messages_marker",{"name":"i18n","hash":{},"hashTypes":{},"hashContexts":{},"types":["StringLiteral"],"contexts":[depth0],"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":45}}}))
    + "\n        "
    + alias3((lookupProperty(helpers,"d-icon")||(depth0 && lookupProperty(depth0,"d-icon"))||alias2).call(alias1,"bp-caret",{"name":"d-icon","hash":{},"hashTypes":{},"hashContexts":{},"types":["StringLiteral"],"contexts":[depth0],"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":29}}}))
    + "\n      </span>\n    </td>\n  </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),"view.isLastVisited",{"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":container.program(1, data, 0),"inverse":container.noop,"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":11,"column":7}}})) != null ? stack1 : "");
},"useData":true});

    addRawTemplate("list/visited-line", template, {
      core: true,
      pluginName: undefined,
      hasModernReplacement: false,
    });

    export default template;
  